// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("modernizr/modernizr.custom.js");

require("@rails/ujs").start();
require("turbolinks").start();
// require(""@rails/activestorage").start()
// require(""channels")

//--- Bootstrap
//import 'bootstrap';

import appInit from './angle/app.init.js';
document.addEventListener('DOMContentLoaded', appInit);

// ATLP-5485
import Rails from "@rails/ujs";
window.Rails = Rails;
if (Rails.fire(document, "rails:attachBindings")) {
    try {
        Rails.start();
    } catch (error) {
        console.log('rails-ujs has already been loaded, ignore')
    }
}

// dev jquery is not showing up as being loaded, try explicit require
global.$ = require('jquery')
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
console.log('jquery loaded')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
